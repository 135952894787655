<template>
    <div align="center">
        <br>
        <div align="center">
            <span class="text-h4">Informazioni sul portafoglio</span>
            <hr>
        </div>
        <br><br>

        <div class="justify-center row">
            <div class="col-12 col-md-2">
                <!--<q-input outlined v-model="anno" label="Anno di produzione" /> //-->
                <q-select outlined v-model="anno" :options="options" label="Anno di produzione" />
            </div>

            <q-btn color="deep-orange" label="Cerca" @click.native="onEstraiDati"/>

        </div>
        <br>

        <q-card flat>
            <q-tabs
                v-model="tab"
                align="center"
                narrow-indicator>
                    <q-tab name="preventivi" label="Preventivi" class="text-weight-bolder" />
                    <q-tab name="pratiche" label="Pratiche" class="text-weight-bolder" />
            </q-tabs>

            <q-tab-panels v-model="tab" animated ref="tabs"
                transition-prev="jump-down"
                transition-next="jump-down">

                <q-tab-panel name="preventivi">
                    <div class="row justify-center" style="background-color: #fff;">
                        <div class="col-md-4" align="left">
                            <h5 class="q-mb-sm" style="color: #000;">Stato dei preventivi per Area</h5>
                            <QQBarChart :chartData="dati_preventivi" />
                        </div>

                        <div class="col-md-3 q-pl-md" align="left">
                            <h5 class="q-mb-sm" style="color: #000;">Percentuale stato dei preventivi</h5>
                            <QQPieChart :chartData="dati_preventivi_pie" />
                            <br>
                            <table width="100%" style="color: #000;">
                                <tbody>
                                    <tr>
                                        <td><strong>Totale preventivi analizzati</strong>:</td>
                                        <td align="right">{{totale_preventivi}}</td>
                                    </tr>
                                    <tr><td colspan="2"><hr></td></tr>
                                    <tr>
                                        <td>Preventivi attivi:</td>
                                        <td align="right">{{attivi_percentuale}} %</td>
                                    </tr>
                                    <tr>
                                        <td>Preventivi annullati:</td>
                                        <td align="right">{{annullati_percentuale}} %</td>
                                    </tr>
                                    <tr>
                                        <td>Preventivi scaduti:</td>
                                        <td align="right">{{scaduti_percentuale}} %</td>
                                    </tr>
                                    <tr>
                                        <td>Preventivi convertiti in Pratica:</td>
                                        <td align="right">{{trasformati_percentuale}} %</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br><br>
                        </div>
                    </div>
                </q-tab-panel>

                <q-tab-panel name="pratiche">
                    <div class="row justify-center" >
                        <div class="col-12 col-md-12">
                            <q-list bordered separator>
                                <q-item>
                                    <q-item-section>
                                        <q-item-label><div style="font-size: 22px;">SITUAZIONE PRATICHE</div></q-item-label>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label caption><div align="center">DEFINITE</div></q-item-label>
                                        <q-item-label><div style="font-size: 24px; text-align: center;">{{getTotalePraticheDefinite()}}</div></q-item-label>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label caption><div align="center">TOTALE</div></q-item-label>
                                        <q-item-label><div style="font-size: 24px; text-align: center;">{{getTotalePratiche()}}</div></q-item-label>
                                    </q-item-section>
                                </q-item>
                            </q-list>
                            <br><br>
                        </div>
                        <div class="col-12 col-md-4" align="left">

                            <h5 class="q-mb-sm">Stato delle pratiche</h5>

                            <q-list bordered separator>
                                <q-item v-for="(pratica,index) in dati_pratiche.composizionePratiche"
                                            v-bind:key="index">
                                    <q-item-section>
                                        <q-item-label>{{pratica.attivita}}</q-item-label>
                                    </q-item-section>
                                    <q-item-section>

                                        <q-item-label>
                                            <table width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td>In attesa di lavorazione:</td>
                                                        <td><strong>{{pratica.attesa_lavorazione}}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td>In lavorazione:</td>
                                                        <td><strong>{{pratica.lavorazione}}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Annullate:</td>
                                                        <td><strong>{{pratica.annullate}}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td>In attesa intervento Ufficio:</td>
                                                        <td><strong>{{pratica.attesaufficio}}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td>In attesa intervento BackOffice:</td>
                                                        <td><strong>{{pratica.attesabackoffice}}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Definite:</td>
                                                        <td><strong>{{pratica.definite}}</strong></td>
                                                    </tr>
                                                    <tr><td colspan="2"><hr></td></tr>
                                                    <tr>
                                                        <td><strong>Totale:</strong></td>
                                                        <td><strong>{{pratica.totale}}</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </q-item-label>
                                    </q-item-section>
                                </q-item>

                            </q-list>

                        </div>

                        <div class="col-md-4 q-pl-md q-ml-md" align="left" style="background-color: #fff;">
                            <h5 class="q-mb-sm" style="color: #000;">Questi dati sono falsi</h5>
                            <QQHorizBarChart :chartData="horizChartData" />
                        </div>

                    </div>
                </q-tab-panel>

            </q-tab-panels>
        </q-card>

        <!--<div class="row justify-center">
            <div class="col-md-3">
                <h5>Stato dei preventivi per Area</h5>
                <QQBarChart :chartData="dati_preventivi" />
            </div>

            <div class="col-md-3">
                <h5>Esempio con QQPieChart.vue</h5>
                <QQPieChart :chartData="pieChartData" />
            </div>

            <div class="col-md-3">
                <h5>Esempio con QQDoughnutChart.vue</h5>
                <QQDoughnutChart :chartData="doughnutChartData" />
            </div>

            <div class="col-md-3">
                <h5>Esempio con QQHorizBarChart.vue</h5>
                <QQHorizBarChart :chartData="horizChartData" />
            </div>

        </div>//-->
        <br><br><br><br><br>
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    import QQBarChart from "@/components/Charts/QQBarChart.vue";
    import QQHorizBarChart from "@/components/Charts/QQHorizBarChart.vue";
    import QQPieChart from "@/components/Charts/QQPieChart.vue";
    //import QQDoughnutChart from "@/components/Charts/QQDoughnutChart.vue";

    export default {
        name: "MenuStatistiche",
        data() {
            return {
                dati_preventivi: {},
                dati_preventivi_pie: {},
                dati_pratiche: {},
                tab: 'preventivi',
                anno: 2022,
                options: [],
                attivi_percentuale: 0,
                annullati_percentuale: 0,
                scaduti_percentuale: 0,
                trasformati_percentuale: 0,
                totale_preventivi: 0,
                horizChartData: {
                    labels: [ 'RCP_Tecnici', 'RCP_Medici', 'Linea persona','Linea aziende' ],
                    //backgroundColor: ["red","blue","green","yellow","gray","#800000","#00FFA0"],
                    datasets: [
                        {
                            label: "Anno 2021",
                            data: [ 40, 20, 37,28 ],
                            backgroundColor: "blue"
                        },
                        {
                            label: "Anno 2022",
                            data: [ 45, 25, 12, 4 ],
                            backgroundColor: "green"
                        }
                    ]
                }
            }
        },
        components: {
            QQBarChart,
            QQPieChart,
            //QQDoughnutChart,
            QQHorizBarChart
        },
        computed: {

        },
        methods: {
            ...mapActions({
                fetchDatiPreventivi: "gestioneStatistiche/fetchDatiPreventivi",
                fetchDatiPratiche: "gestioneStatistiche/fetchDatiPratiche"
            }),
            getTotalePratiche() {
                var len = this.dati_pratiche.composizionePratiche.length;
                var totale = 0;
                for (var i=0;i<len;i++) {
                    totale += this.dati_pratiche.composizionePratiche[i].totale;
                }

                return totale;
            },
            getTotalePraticheDefinite() {
                var len = this.dati_pratiche.composizionePratiche.length;
                var totale = 0;
                for (var i=0;i<len;i++) {
                    totale += this.dati_pratiche.composizionePratiche[i].definite;
                }

                return totale;
            },
            async onEstraiDati() {
                var oggi = new Date();
                var anno = oggi.getFullYear();
                this.options = [anno,anno-1,anno-2];

                this.dati_preventivi = await this.fetchDatiPreventivi(this.anno); // Per ora inchiodo l'anno poi sostituisco

                // Fa le somme per la torta
                var attivi = 0;
                var annullati = 0;
                var scaduti = 0;
                var trasformati = 0;
                var len = this.dati_preventivi.datasets.length;

                for (var i=0;i<len;i++) {
                    var d = this.dati_preventivi.datasets[i];
                    attivi += d.data[0];
                    annullati += d.data[1];
                    scaduti += d.data[2];
                    trasformati += d.data[3];
                }

                this.dati_preventivi_pie = {
                    labels: [ 'Attivi', 'Annullati', 'Scaduti' ,'Convertiti in pratica'],
                    datasets: [
                        {
                            label: [ 'Attivi', 'Annullati', 'Scaduti' ,'Convertiti in pratica'],
                            data: [
                                attivi,annullati,scaduti,trasformati
                            ],
                            backgroundColor: ["red","green","black","blue"]
                        }
                    ]
                }
                this.totale_preventivi = attivi + annullati + scaduti + trasformati;

                this.attivi_percentuale = 0;
                this.annullati_percentuale = 0;
                this.scaduti_percentuale = 0;
                this.trasformati_percentuale = 0;

                if (this.totale_preventivi > 0) {
                    this.attivi_percentuale = (100 * (attivi / this.totale_preventivi)).toFixed(0);
                    this.annullati_percentuale = (100 * (annullati / this.totale_preventivi)).toFixed(0);
                    this.scaduti_percentuale = (100 * (scaduti / this.totale_preventivi)).toFixed(0);
                    this.trasformati_percentuale = 100 - (parseFloat(this.attivi_percentuale) +
                                                        parseFloat(this.annullati_percentuale) +
                                                        parseFloat(this.scaduti_percentuale));
                }

                this.dati_pratiche = await this.fetchDatiPratiche(this.anno);
            }
        },
        async mounted() {
            var oggi = new Date();
            this.anno = oggi.getFullYear();

            await this.onEstraiDati();

            //this.dati_pratiche = await this.fetchDatiPratiche(this.anno);

            //console.log("dati pratiche:",this.dati_pratiche);
        },
        async activated() {

        }
    }
</script>
<style>
    .table {
        width: 100%;
    }
    .table-bordered {
        border-collapse: collapse;
        width: 100%;
    }

    .table-bordered tbody tr td, .table tr th {
        /*border: 1px solid #404040;*/
        border: 1px solid;
        padding: 8px;
    }

    .table-bordered thead tr th {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        text-align: left;
        /*color: #404040;
        background-color: #D0D0D0;*/
        border: 1px SOLID #404040;
        background-color: #ffcc99;
        color: #000;
    }

    .table-bordered tFoot tr td {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        padding-right: 8px;
        text-align: right;
        border: 1px SOLID;

    }
</style>

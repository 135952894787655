<!--
ESEMPIO DI DATI PER IL DISEGNO DI UN GRAFICO A BARRE
-----------------------------------------------------

chartData: {
    labels: [ 'Gennaio', 'Febbraio', 'Marzo' ],
    datasets: [
        {
            label: "Anno 2021",
            data: [40, 20, 12],
            backgroundColor: "red"
        },
        {
            label: "Anno 2022",
            data: [60,28,23],
            backgroundColor: "green"
        }
    ]
},

//-->
<template>
    <div style="max-width: 700px;">
        <Bar
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"

        />
    </div>

</template>
<script>
    //import { Bar } from 'vue-chartjs'
    import { Bar } from 'vue-chartjs/legacy'
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

    export default {
        name: 'QQBarChart',
        components: { Bar },
        data() {
            return {
            //    chartData: {
            //        labels: [ 'January', 'February', 'March' ],
            //        datasets: [ { data: [40, 20, 12] }, {data: [60,28,23]} ]
            //    },
                chartOptions: {
                    responsive: true
                }
            }
        },
        props: {
            chartId: {
                type: String,
                default: 'bar-chart'
            },
            datasetIdKey: {
                type: String,
                default: 'label'
            },
        /*    width: {
                type: Number,
                default: 200
            },
            height: {
                type: Number,
                default: 200
            },*/
            cssClasses: {
                default: '',
                type: String
            },
            styles: {
                type: Object,
                default: () => {}
            },
            plugins: {
                type: Object,
                default: () => {}
            },
            chartData: {
                type: Object,
                required: true
            }
        }
    }
</script>
